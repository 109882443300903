import { Box, Icon, Divider, Flex } from "@chakra-ui/react";
import React from "react";
import { IoCloseSharp, IoMenuSharp } from "react-icons/io5";

export const MobileHamburgerMenu = props => {
  const { onClick, isOpen } = props;
  return (
    <Box  display={{ lg: "none" }}>
      <Flex>
        <Box as="button" onClick={onClick} pr="6" fontSize="xl">
          {isOpen ? (
            <Box aria-hidden as={Icon} w="24px" h="24px">
              <IoCloseSharp />
            </Box>
          ) : (
            <Box aria-hidden as={Icon} w="24px" h="24px">
              <IoMenuSharp />
            </Box>
          )}
          <Box srOnly>{isOpen ? "Close menu" : "Open menu"}</Box>
        </Box>
        <Divider height="72px" orientation="vertical" />
      </Flex>
    </Box>
  );
};
