import React from "react";
import { Text } from "@chakra-ui/react";

const FooterText = ({ children, ...props }) => (
  <Text color="gray.500" fontSize={{ base: "xs", md: "sm" }} {...props}>
    {children}
  </Text>
);

export default FooterText;
