import { Box, Button, Flex, Text, VStack } from "@chakra-ui/react";
import { Link } from "gatsby";
import React from "react";
import { IoLogoLinkedin, IoLogoTwitter } from "react-icons/io5";
import Logo from "../shared/Logo/Logo";
import FooterText from "./FooterText";
import FooterTitle from "./FooterTitle";

const Footer = () => {
  return (
    <footer>
      <Box
        marginTop="100px"
        width="100%"
        height={{ base: "617px", md: "481px", lg: "368px" }}
        background="linear-gradient(262.12deg, #CBD5E0 -21.79%, #F6FAFF 54.22%)"
      >
        <VStack
          position="relative"
          top="-32px"
          p={{ base: "4", md: "6", lg: "8" }}
          backgroundColor="white"
          mx={{ base: "6", md: "10", lg: "20", "3xl": "40" }}
          alignItems="left"
          spacing="8"
        >
          <Box>
            <Logo />
          </Box>
          <Flex justifyContent="space-between" flexFlow="wrap">
            <VStack spacing="4" alignItems="left" height="100%">
              <FooterTitle>Contact Address</FooterTitle>
              <Box>
                <FooterText>Motar.me</FooterText>
                <FooterText>CoLab Innovation Centre</FooterText>
                <FooterText>Atlantic Technology University (ATU)</FooterText>
                <FooterText>Letterkenny, county Donegal</FooterText>
                <FooterText>F92 PHF4</FooterText>
                <FooterText>Ireland</FooterText>
                <FooterText>
                  Email:{" "}
                  <Text
                    as="a"
                    href="mailto:info@motar.me"
                    textDecoration="underline"
                  >
                    info@motar.me
                  </Text>
                </FooterText>
                <FooterText>Tel: +353 74 9116689 (Ireland)</FooterText>
                <FooterText>Tel: (332) 245 0205 (USA)</FooterText>
              </Box>
            </VStack>
            <VStack
              spacing="4"
              alignItems="left"
              width="181px"
              height="100%"
              marginLeft={{ base: "0", md: "auto" }}
              pt={{ base: "6", md: "0" }}
            >
              <FooterTitle>Company</FooterTitle>
              <FooterText
                as="a"
                href={process.env.GATSBY_APP_URL}
                target="_blank"
                rel="noopener noreferrer"
                _hover={{
                  color: "green.900",
                }}
              >
                Motar.me
              </FooterText>
              <Link to="/contact">
                <FooterText
                  _hover={{
                    color: "green.900",
                  }}
                >
                  Contact us
                </FooterText>
              </Link>
              <Link to="/privacy">
                <FooterText
                  _hover={{
                    color: "green.900",
                  }}
                >
                  Privacy Policy
                </FooterText>
              </Link>
              <Link to="/tos">
                <FooterText
                  _hover={{
                    color: "green.900",
                  }}
                >
                  Terms and Conditions
                </FooterText>
              </Link>
            </VStack>
            <VStack
              spacing="4"
              alignItems="left"
              height="100%"
              pt={{ base: "6", lg: "0" }}
              width={{ base: "100%", md: "unset" }}
            >
              <FooterTitle>Follow us</FooterTitle>
              <Button
                as="a"
                href="https://twitter.com/motarme"
                target="_blank"
                rel="noopener noreferrer"
                variant="social"
                leftIcon={<IoLogoTwitter />}
              >
                Follow Motarme on Twitter
              </Button>
              <Button
                as="a"
                href="https://www.linkedin.com/company/motar-me/?originalSubdomain=ie"
                target="_blank"
                rel="noopener noreferrer"
                variant="social"
                leftIcon={<IoLogoLinkedin />}
                paddingLeft="0"
              >
                Get in touch on Linkedin
              </Button>
            </VStack>
          </Flex>
        </VStack>
      </Box>
    </footer>
  );
};

export default Footer;
