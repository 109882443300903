import React from "react";
import { Text, HStack, Icon } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { IoChevronDownSharp } from "react-icons/io5";

const NavDropdown = styled.div`
  transition: max-height 0.3s cubic-bezier(0, 0, 0.2, 1);
  max-height: 0;
  overflow: hidden;
  position: absolute;
  top: 72px;
  left: 30px;

  @media screen and (min-width: 62em) {
    left: 40px;
  }
  @media screen and (min-width: 80em) {
    top: 49px;
    left: 0;
  }
  width: max-content;
  transform: translateX(-1.77777777778em);
  border: none;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.06);
  background-color: white;
`;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

const Container = styled.div`
  height: 100%;
  ${Wrapper} {
    &:hover ${NavDropdown} {
      max-height: 136px;
      transition: max-height 0.3s cubic-bezier(0.4, 0, 1, 1);
      border: 1px solid #e2e8f0;
    }
    #chevron_down {
      transition: transform 0.3s ease-out;
    }
    &:hover #chevron_down {
      transform: rotate(180deg);
      stroke: #2ba530;
    }
  }
`;

const HMenu = ({ children }) => {
  return (
    <Container>
      <Wrapper>
        <HStack
          id="features-sec"
          cursor="pointer"
          spacing="2"
          color="gray.600"
          _hover={{
            color: "green.500",
          }}
          fontSize="lg"
          position={{ base: "unset", xl: "relative" }}
          display="flex"
          alignItems="center"
          py="2"
          px="3"
        >
          <Text>Features</Text>
          <Icon id="chevron_down" as={IoChevronDownSharp} color="gray.400" />
          <NavDropdown active={false}>
            <HStack width={{ md: "100%", xl: "unset" }}>{children}</HStack>
          </NavDropdown>
        </HStack>
      </Wrapper>
    </Container>
  );
};

export default HMenu;
