import React from "react";
import { Box, HStack, Text, VStack } from "@chakra-ui/react";

const MenuCard = ({ children, text, icon }) => {
  return (
    <HStack
      px={{ base: "6", lg: "6" }}
      py={{ base: "4", lg: "8" }}
      backgroundColor="white"
      _hover={{
        backgroundColor: "#F7FAFC",
      }}
      width={{ base: "100%", md: "unset" }}
      spacing="2"
      display="flex"
      alignItems="start"
    >
      <Box>{icon}</Box>
      {/* {icon && <Icon as={icon} w="40px" h="40px" />} */}
      {/* <Icon w="40px" h="40px">
        {icon}
      </Icon> */}
      <VStack
        width={{ base: "unset", sm: "150px", lg: "200px" }}
        alignItems="left"
        spacing="0"
      >
        <Text
          fontWeight="bold"
          color="gray.800"
          fontSize={{ base: "lg", lg: "xl" }}
        >
          {children}
        </Text>
        <Text fontSize="sm" mt="0" color="gray.800">
          {text}
        </Text>
      </VStack>
    </HStack>
  );
};

export default MenuCard;
