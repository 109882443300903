import React, { useState } from "react";
import { Link } from "gatsby";
import {
  Flex,
  Button,
  Box,
  useBreakpointValue,
  ButtonGroup,
} from "@chakra-ui/react";
import { NavMenu } from "./NavMenu/NavMenu";
import { MobileHamburgerMenu } from "./MobileHamburgerMenu/MobileHamburgerMenu";
import Logo from "../shared/Logo/Logo";

const Header = () => {
  const buttonSize = useBreakpointValue({ base: "sm", md: "md", "3xl": "lg" });
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const closeMenu = () => {
    setIsMenuOpen(false);
  };
  return (
    <Flex
      as="header"
      position="fixed"
      top="0"
      left="0"
      width="100%"
      align="center"
      color="gray.600"
      backgroundColor="white"
      px={{ base: "6", lg: "20", "3xl": "40" }}
      minH={{ base: "72px", "3xl": "80px" }}
      boxShadow="0px 2px 22px rgba(0, 0, 0, 0.04)"
      zIndex="39"
    >
      <Flex align="center" w="100%" h="72px">
        <MobileHamburgerMenu onClick={toggleMenu} isOpen={isMenuOpen} />
        <Link to="/">
          <Box
            flexShrink={0}
            ml={{ base: "12px", lg: "0" }}
            marginEnd={{ base: "0", xl: "60px" }}
            maxWidth={{ base: "120px", md: "unset" }}
          >
            <Logo />
          </Box>
        </Link>
        <NavMenu.Mobile
          isOpen={isMenuOpen}
          closeMenu={closeMenu}
        />
        <NavMenu.Desktop />
        <ButtonGroup gap="12px" ml="auto">
          <Button
            size={buttonSize}
            as="a"
            href={process.env.GATSBY_APP_URL}
            target="_blank"
            rel="noopener noreferrer"
            variant="outline"
          >
            Log In
          </Button>
          <Button
            size={buttonSize}
            as="a"
            href={process.env.GATSBY_APP_URL + "/register"}
            target="_blank"
            rel="noopener noreferrer"
            marginInlineStart="0!important"
            sx={{
              "@media screen and (max-width: 500px)": {
                display: "none",
              },
            }}
          >
            Start My Free Trial
          </Button>
        </ButtonGroup>
      </Flex>
    </Flex>
  );
};

export default Header;
