import {
  Box,
  Divider,
  Flex,
  HStack,
  Button,
  Accordion,
  AccordionItem,
  AccordionIcon,
  AccordionButton,
  AccordionPanel,
} from "@chakra-ui/react";
import React from "react";
import HMenu from "../HMenu/HMenu";
import { NavItem } from "./NavItem";
import { Link } from "gatsby";
import MenuCard from "../HMenu/MenuCard";
import TemplatesIcon from "../../../images/vector/feature_icons/templates.svg";
import EmailCampaignsIcon from "../../../images/vector/feature_icons/email_campaigns.svg";
import ReportsIcon from "../../../images/vector/feature_icons/reports.svg";

const FeaturesItems = ({ closeMenu }) => (
  <>
    <Link
      to="/#features"
      state={{
        features: "campaigns",
      }}
      onClick={() => {
        closeMenu && closeMenu();
      }}
    >
      <MenuCard
        text="Create and schedule email sequences"
        icon={<EmailCampaignsIcon />}
      >
        Email Campaigns
      </MenuCard>
    </Link>
    <Link
      to="/#features"
      state={{
        features: "reports",
      }}
      onClick={() => {
        closeMenu && closeMenu();
      }}
    >
      <MenuCard
        text="Generate reports showcasing responses for each campaign"
        icon={<ReportsIcon />}
      >
        Reports
      </MenuCard>
    </Link>
    <Link
      to="/#features"
      state={{
        features: "templates",
      }}
      onClick={() => {
        closeMenu && closeMenu();
      }}
    >
      <MenuCard
        text="Create custom email templates and save time"
        icon={<TemplatesIcon />}
      >
        Templates{" "}
      </MenuCard>
    </Link>
  </>
);

const MobileNavMenu = props => {
  const { isOpen, closeMenu } = props;
  return (
    <Flex
      hidden={!isOpen}
      as="nav"
      direction="column"
      bg="white"
      position="fixed"
      height="calc(100vh - 72px)"
      top="72px"
      insetX="0"
      zIndex={10}
      w="full"
      overflow="auto"
      display={{ base: "flex", lg: "none" }}
    >
      <Box mb="4">
        <Divider orientation="horizontal" borderColor="gray.200" />
        <NavItem.Mobile label="Contact" href="/contact" />
        <Divider orientation="horizontal" />
        <NavItem.Mobile label="Motar.me plugin" href="/plugin" />
        <Divider orientation="horizontal" />
        <Accordion allowMultiple={true}>
          <AccordionItem borderTopWidth="none" borderBottom="none">
            <AccordionButton
              p="0"
              _hover={{ bg: "transparent" }}
              _focus={{ boxShadow: "none" }}
            >
              <NavItem.Mobile label="Features" clickable={false}>
                <AccordionIcon />
              </NavItem.Mobile>
            </AccordionButton>
            <AccordionPanel p="0" pb={4}>
              <FeaturesItems closeMenu={closeMenu} />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <Divider orientation="horizontal" />
        <NavItem.Mobile label="Pricing" href="/pricing" />
      </Box>
      <Box
        mt="auto"
        p="6"
        sx={{
          "@media screen and (min-width: 501px)": {
            display: "none",
          },
        }}
      >
        <Button
          as="a"
          size="lg"
          href={process.env.GATSBY_APP_URL + "/register"}
          target="_blank"
          rel="noopener noreferrer"
          w="100%"
        >
          Start Your 14 Day Free Trial
        </Button>
      </Box>
    </Flex>
  );
};

const DesktopNavMenu = () => (
  <HStack
    height="100%"
    spacing="3"
    flex="1"
    display={{ base: "none", lg: "flex" }}
  >
    <NavItem.Desktop label="Contact" href="/contact" />
    <HMenu>
      <FeaturesItems />
    </HMenu>
    <NavItem.Desktop label="Motar.me plugin" href="/plugin" />
    <NavItem.Desktop label="Pricing" href="/pricing" />
  </HStack>
);

export const NavMenu = {
  Mobile: MobileNavMenu,
  Desktop: DesktopNavMenu,
};
