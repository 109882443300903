import React from "react";
import { Box } from "@chakra-ui/react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Fonts from "../../theme/Fonts";

const Layout = ({ children }) => (
  <>
    <Fonts />
    <Header />
    <Box
      d="grid"
      maxW="100vw"
      gridTemplateColumns={{
        base: "24px 1fr 24px",
        md: "40px 1fr 40px",
        lg: "80px 1fr 80px",
        "3xl": "1fr 1600px 1fr",
      }}
    >
      {children}
    </Box>
    <Footer />
  </>
);

export default Layout;
