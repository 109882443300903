import { Box, Text } from "@chakra-ui/react";
import { Link } from "gatsby";
import React from "react";

const DesktopNavItem = props => {
  const { label, href = "#", active } = props;
  return (
    <Link to={href}>
      <Text
        aria-current={active ? "page" : undefined}
        px="3"
        py="2"
        transition="all 0.2s"
        color="gray.600"
        _hover={{ color: "green.500" }}
        _activeLink={{ color: "green.500" }}
        marginInline="0!important"
        whiteSpace="nowrap"
        fontSize="lg"
      >
        {label}
      </Text>
    </Link>
  );
};

const MobileNavItem = props => {
  const { label, href = "#", active, clickable = true, children } = props;
  return (
    <Box
      as={clickable ? "a" : "div"}
      display="block"
      href={href}
      px="6"
      py="6"
      rounded="md"
      color="gray.800"
      aria-current={active ? "page" : undefined}
      w="100%"
      textAlign="left"
      _hover={{ color: "green.500" }}
      _activeLink={{ color: "green.500" }}
    >
      {label} {children}
    </Box>
  );
};

export const NavItem = {
  Desktop: DesktopNavItem,
  Mobile: MobileNavItem,
};
