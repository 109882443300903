import React from "react";
import { Text } from "@chakra-ui/react";

const FooterTitle = ({ children }) => (
  <Text fontWeight="bold" fontSize="sm">
    {children}
  </Text>
);

export default FooterTitle;
